<template>
    <div v-if="showBanner" class="create-agent-banner">
      
      <div class="banner-content">

        

                <!-- Right Side - Image Carousel -->
                <div class="carousel-container">
          <div class="carousel-wrapper" :style="{ transform: `translateY(-${currentIndex * 100}%)` }">
            <img v-for="(image, index) in carouselImages" 
                 :key="index" 
                 :src="image.url" 
                 :alt="image.alt"
                 class="carousel-image" />
          </div>
        </div>

        
        
        <!-- Left Side -->
        <div class="left-content">
          <!-- Close Button -->
         
      
          <!-- Banner Title -->
          <h3>{{ title }}</h3>
          <p>{{ description }}</p>
      
          <!-- Steps Section -->
          <ul class="banner-steps">
            <li v-for="(step, index) in steps" :key="index">
              ✅ <strong>Step {{ index + 1 }}:</strong> {{ step }}
            </li>
          </ul>

        
          <!-- CTA Button -->
          <div class="button-container">
            <b-button variant="secondary" @click="goToCreateAgent()" class="upload-btn">
              <feather-icon size="16" icon="ZapIcon" />
              {{ buttonText }}
            </b-button>
            
          </div>
          
        </div>

        <button class="close-banner-btn" @click="$emit('update:showBanner', false)">&times;</button>

  
        
      </div>
    </div>
</template>

<script>
export default {
  props: {
    showBanner: Boolean,
    title: {
      type: String,
      default: "🚀 Create Unlimited Photoshoot Images ",
    },
    description: {
      type: String,
      default: "No more expensive shoots! Get unlimited professional images in 3 easy steps—perfect for marketers & influencers.",
    },
    steps: {
      type: Array,
      default: () => [
        "Upload Your Product or Portrait Photos  📸",
        "Build Your AI-Powered Creative Agent ⚡",
        "Create Stunning, Realistic Photos in Seconds with Simple Prompts ✨",
      ],
    },
    buttonText: {
      type: String,
      default: "Start Creating For Free",
    },
  },
  data() {
    return {
      currentIndex: 0,
      carouselImages: [
        { url: 'https://media.briks.ai/images/image-fbf49b08-799a-4428-a5a7-af36686fa491.jpg', alt: 'Sample AI Generated Image 1' },
        { url: 'https://media.briks.ai/images/image-3151445c-c52e-4f5f-ada8-ca0e87c9a143.jpg', alt: 'Sample AI Generated Image 4' },
        { url: 'https://media.briks.ai/images/blue_dress_changed.png', alt: 'Sample AI Generated Image 4' },
        { url: 'https://media.briks.ai/images/image-2984bfd7-2f01-4efe-a160-155595f25458.png', alt: 'Sample AI Generated Image 2' },
        { url: 'https://media.briks.ai/images/image-a6b52741-008b-4a07-a78f-b0f6a7e75f38.png', alt: 'Sample AI Generated Image 2' },
        { url: 'https://media.briks.ai/images/image-a042b569-efd3-496e-bfd6-f2a0281f1adb.png', alt: 'Sample AI Generated Image 3' },
        { url: 'https://media.briks.ai/images/image-3ab2ced3-8ed3-4941-8ff8-c33e744e5f48.png', alt: 'Sample AI Generated Image 3' },
        { url: 'https://media.briks.ai/images/image-55293a7d-3f93-454b-917f-0ed6bac5cc10.png', alt: 'Sample AI Generated Image 4' },
        { url: 'https://media.briks.ai/images/image-73304b6b-357c-44e5-b5b8-b5349f9571e2.png', alt: 'Sample AI Generated Image 4' },
        { url: 'https://media.briks.ai/images/image-a0eb6b91-2541-4a8e-8e36-f9b79d9e40c2.png', alt: 'Sample AI Generated Image 4' },
        { url: 'https://media.briks.ai/images/image-68574263-9c04-4565-b32d-77b07f9e0d05.png', alt: 'Sample AI Generated Image 4' },
        { url: 'https://media.briks.ai/images/image-31cc2d26-cc42-4579-a535-2dac75807266.png', alt: 'Sample AI Generated Image 4' },
        { url: 'https://media.briks.ai/images/image-94078b12-7518-4534-8349-ec88d872de57.png', alt: 'Sample AI Generated Image 4' },
        { url: 'https://media.briks.ai/images/image-95e9589b-58cb-4342-af63-fb3d0a55f4ff.png', alt: 'Sample AI Generated Image 4' },
        { url: 'https://media.briks.ai/images/image-55f003be-9aed-48f9-9af1-e66efcc6671b.png', alt: 'Sample AI Generated Image 4' },

      ]
    }
    
  },
  mounted() {
    this.startCarousel();
  },
  beforeDestroy() {
    this.stopCarousel();
  },
  methods: {
    startCarousel() {
      this.carouselInterval = setInterval(() => {
        this.currentIndex = (this.currentIndex + 1) % this.carouselImages.length;
      }, 3000);
    },
    stopCarousel() {
      if (this.carouselInterval) {
        clearInterval(this.carouselInterval);
      }
    },
    goToCreateAgent() {
  const userData = localStorage.getItem("userData");
  if (!userData) {
    this.$bvModal.show("modal-login");
    return;
  }
  this.$router.push('/products');
},
  }
};
</script>

<style scoped>
.create-agent-banner {
  position: fixed;
  top: 25px;
  left: 50%;
  transform: translateX(-50%);
  background: white;
  border-radius: 12px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  max-width: 1000px;
  width: 100%;
  z-index: 1000;
  margin: auto;
  text-align: left; /* Changed from center to left */
}

.banner-content {
  display: flex;
  gap: 2rem;
  align-items: start;
  text-align: left;
  margin-left: 2rem;
}

.left-content {
  flex: 1;
  position: relative;
}

.carousel-container {
  flex: 0 0 300px;
  height: 300px;
  overflow: hidden;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.carousel-wrapper {
  height: 100%;
  transition: transform 0.5s ease-in-out;
}

.carousel-image {
  width: 100%;
  object-fit: contain;
  display: block;
}

.upload-btn {
  padding: 12px 24px;
  font-size: 1.1rem;
  font-weight: 500;
  border-radius: 8px;
  margin-left:0rem;
  margin-bottom: 1rem;

  transition: transform 0.2s ease, box-shadow 0.2s ease;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.banner-steps {
  text-align: left;
  display: inline-block;
  margin-top: 0px;
  list-style: none;
  padding: 0;
}

.banner-steps li {
  margin: 10px 0;
}

.button-container {
  display: flex;
  justify-content: center;
  margin-top: 0px;
}


.upload-btn:hover {
  transform: translateY(-1px);
  box-shadow: 0 4px 8px rgba(0,0,0,0.15);
}

/* Add pulse animation for extra attention */
@keyframes pulse {
  0% { box-shadow: 0 0 0 0 rgba(0, 255, 213, 0.5); }  /* Bright teal */
  70% { box-shadow: 0 0 0 10px rgba(0, 255, 213, 0); }
  100% { box-shadow: 0 0 0 0 rgba(0, 255, 213, 0); }
}

.upload-btn:not(:hover) {
  animation: pulse 2s infinite;
}

.create-agent-banner h3 {
  margin: 0;
  margin-bottom: 10px;
  font-size: 1.7rem;
  padding-top: 10px;
  /* color: white; */
}

.create-agent-banner p {
  margin: 0;
  margin-top: 15px;
  margin-bottom: 20px;
  font-size: 1rem;
}

.close-banner-btn:hover {
  color: #cccccc;
}

/* Close Button */
.close-banner-btn {
  position: absolute;
  top: 8px;
  right: 8px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  padding: 8px;
  line-height: 1;
  color: #555;
  transition: color 0.2s;
}
.close-banner-btn:hover {
  color: #000;
}

.create-agent-banner .btn-primary {
  background-color: #38c03f;
  border-color: #007bff;
  color: #28a745;
}


.create-agent-banner .btn-primary:hover {
  background-color: #218838;
  border-color: #1e7e34;
}

.create-agent-banner .btn-primary:hover {
  background-color: #218838;
  border-color: #1e7e34;
}

/* Responsive Design */
@media (max-width: 600px) {
  .create-agent-banner {
    padding: 15px;
    width: 100%;
  }

  .create-agent-banner h3 {
    font-size: 1.25rem;
  }

  .create-agent-banner p {
    font-size: 1rem;
  }

  .banner-content {
    flex-direction: column;
    align-items: center;
  }
  
  .carousel-container {
    width: 100%; /* Full width container */
    /* height: 250px;  */
    margin: 1rem; 
  }
  
  .carousel-wrapper {
    width: 100%;
  }

  .carousel-container {
  flex: 0 0 300px;
  height: 100%;
  width: auto;
  overflow: hidden;
}

  .carousel-image {
  width: 100%;
  height: 100%;
  background: transparent;
  object-fit: contain;
}
}
</style>
